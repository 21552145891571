<template>
  <div>
    <div class="top">
        <div class="top_box">
            <h2>{{index.title}}</h2>
            <div class="path">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item >关于我们</el-breadcrumb-item>
                <el-breadcrumb-item >{{index.title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
    </div>
    <div class="center">
        <div class="center_box" v-html="this.index.contact">
            
        </div>
    </div>
    
    <div class="footer">
        <div class="footer_box">

        </div>
    </div>
  </div>
</template>

<script>
import  config  from "../../config";
export default {
  name: 'About',
  data() {
    return {
        index: {
            title: "联系我们",
            contact: ''
        },
        config: {
            URL: ''
        }
    }
  },
  components: {
    
  },
  create(){
  },
  mounted() {
    this.config.URL = config.URL
    this.getListData()
  },
methods:{
    getListData(){
        var ts = this
        this.$util.post('/user/contactUsData').then(res => {
           ts.index.contact = res.contactUs
        })
    }
}
}
</script>

<style scoped lang='scss'>
    .top{
        background: url('../../images/slogan.png');
        background-size: 100%;
        background-position: center;
        .top_box{
            width: 1200px;
            height: 200px;
            margin: 0 auto;
            text-align: center;
            line-height: 200px;
            color: #fff;
            h2{
                font-size: 30px;
            }
            .path{
                background: #fff;
                border: 1px solid #ddd;
                padding: 20px;
                position: relative;
                top: -28px;
                .el-breadcrumb{
                    font-size: 18px;
                }
            }
        }
    }
    .center{
        padding-top: 80px;
        color: #000;
        .center_box{
            width: 1200px;
            margin: 0 auto;
        }
    }
    .footer{
        .footer_box{
            padding:20px 0;
        }
    }
</style>

